//Local settings

export let appConfiguration = {
  prefix : "ctl",
  ENVIRONMENT : "DEV",  //Possible-Values ['LOCAL', 'DEV', 'COMMERCIAL']


  LOCAL_RASERVICE_Url : "",
  DEV_RASERVICE_Url : "https://raservice.jazzba.io/",
  COMMERCIAL_RASERVICE_Url : "https://raservice.jazzba.io/",

  LOCAL_CATALYST_Url : "",
    DEV_CATALYST_Url : "https://catalystservice.jazzba.io/",
  // DEV_CATALYST_Url : "http://whiztec.ddns.net:8082/",
  COMMERCIAL_CATALYST_Url : "https://catalystservice.jazzba.io/"
  
}


export let IMAGES_BASE_URL = "http://anydatapoint.net/";
export let jazzbaManagePurchasesUrl = "#/app/transactions/purchaseOrder";
export let jazzbaManageSuppliersUrl = "#/app/buy-supply/supplier";
  
export let copyRight = "2020 - 2022 Jazzba Inc."

